import { For } from 'solid-js';

import type { ContentDto, Genre } from '~/modules/api';

import { display } from '~/utils';
import { CarouselGallery, Tile } from '../shared';
import { Slide } from '../ui';

interface TrendingGalleryProps {
  genre: Genre
  title: string
  items: ContentDto[];
}

export function GenreGallery(props: TrendingGalleryProps) {
  return (
    <CarouselGallery
      id={'genre_' + props.genre}
      heading={{
        title: props.title,
        action: {
          url: '/genres/' + props.genre,
          label: 'View all',
          hide: !display('viewAllVertical', props.items?.length ?? 0),
        },
      }}
    >
      <For each={props.items}>
        {item => (
          <Slide class="df-scrollable-list-item-4-col">
            <Tile
              preset="VerticalTileCondensedNoGenre"
              content={item}
              trackingProperties={{ section: 'genre-carousel' }}
            />
          </Slide>
        )}
      </For>
    </CarouselGallery>
  );
}
