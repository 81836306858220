import { For } from 'solid-js';

import type { ContentDto } from '~/modules/api';
import { link } from '~/utils';
import { CarouselGallery, Tile } from '../shared';
import { Slide } from '../ui';

interface NewReleasesGalleryProps {
  items: ContentDto[];
}

const cols = 4;

export function NewReleasesGallery(props: NewReleasesGalleryProps) {
  return (
    <CarouselGallery
      id="new-releases"
      heading={{
        title: 'New Releases',
        action: {
          url: link.toCategory({ category: 'new_releases' }),
          label: 'View all',
          hide: true,
        },
      }}
      columns={cols}
    >
      <For each={props.items}>
        {item => {
          return (
            <Slide class="df-scrollable-list-item-4-col">
              <Tile
                preset="VerticalTileCondensed"
                content={item}
                trackingProperties={{ section: 'new-releases-carousel' }}
              />
            </Slide>
          );
        }}
      </For>
    </CarouselGallery>
  );
}
